import { Entity } from '@plex/entities/entities.model';
import { AccountDetails } from '@plex/fin/models/account';

/*
	YYYY-MM-DD

	https://stackoverflow.com/a/51448473/13180651
	FIXME: type LampyPayloadDate = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;
*/
type LampyPayloadDate = string;
type StartEndType = {
	startDate: string;
	endDate: string;
};
const lampyPayloadDateFormat = 'YYYY-MM-DD';

/*
	whitfieldsPrefix/accountNumber
	e.g. AMV/8400, VTY/8400
	
	https://stackoverflow.com/a/51448473/13180651
	FIXME: type LampyPayloadAccount = `${string}${string}${string}/${number}${number}${number}${number}`;
*/
type LampyPayloadAccount = string;

type LampyPayloadAccountType = 'debtors' | 'postar' | 'postgl';

interface LampySchemeAccountBalancePayload {
	endDate: LampyPayloadDate | StartEndType;
	account: LampyPayloadAccount;
	accountType: LampyPayloadAccountType;
}

interface LampyAccountTransactionsPayload {
	startDate: LampyPayloadDate;
	endDate: LampyPayloadDate;
	account: LampyPayloadAccount;
	accountType: LampyPayloadAccountType;
}

interface LampyAccountsPayload {
	accountType: LampyPayloadAccountType;
	scheme: string;
}

interface SchemeBalancesData {
	prefix: string;
	accounts: any;
	endDate: string;
	buffer: string;
	amountBuffer: string | number;
	overdraft: string | number;
	accountType: string;
}

interface TrialBalanceData {
	prefix: string;
	month: number;
	year: number;
	financialYearStart: string;
	entityDetails: {
		ownBankAccount: boolean;
	};
}

interface OldPastelTrialBalanceData {
	prefix: string;
	month: number;
	year: number;
	financialYearStart: string;
}

export interface AgeAnalysisTotals {
	total?: number;
	current?: number;
	columns: [number?, number?, number?, number?]; // 30, 60, 90, 120 day columns
}

export interface AccountAgeAnalysis extends AgeAnalysisTotals {
	registeredOwnerName: string;
	account: string;
}

interface GeneratedAgeAnalysis {
	accountAgeAnalyses: AccountAgeAnalysis[];
	total: {
		columns: number[];
		current: number;
		total: number;
	};
}

interface NewAgeAnalysisRequirements {
	startYearMonth: string; // Formatted 'YYYY-MM'
	accounts: AccountDetails[];
	entity?: Partial<Entity>;
}

interface LampyFireflyStatementPayload {
	name?: string;
	startDate?: LampyPayloadDate;
	endDate?: LampyPayloadDate;
	accountId?: LampyPayloadAccount;
	accountName?: LampyPayloadAccount;
	entityId?: string;
	statementType?: string;
	isFirefly?: boolean;
}

interface LampyGeneralLedgerPayload {
	startDate: LampyPayloadDate;
	endDate: LampyPayloadDate;
	accounts: [];
	accountType: LampyPayloadAccountType;
	complexPrefix: string;
	complexName: string;
	currentUser: {};
}

interface TransactionAccountPayload {
	startDate: LampyPayloadDate;
	account: string;
	accountType: string;
	endDate: string;
}

export interface TopMeterReadingsRequirements {
	whitfieldsPrefix: string;
	year?: number; // defaults to year of previous month
	month?: number; // defaults to previous month
	order?: 'asc' | 'desc'; // defaults to desc
	accountLimit?: number; // defaults to 10
}

type WhitfieldsCallLampyPayload =
	| LampySchemeAccountBalancePayload
	| LampyAccountTransactionsPayload
	| LampyAccountsPayload
	| SchemeBalancesData
	| LampyGeneralLedgerPayload
	| TrialBalanceData
	| LampyFireflyStatementPayload
	| TransactionAccountPayload
	| NewAgeAnalysisRequirements
	| OldPastelTrialBalanceData
	| TopMeterReadingsRequirements;

export {
	lampyPayloadDateFormat,
	WhitfieldsCallLampyPayload,
	LampyPayloadDate,
	StartEndType,
	LampyPayloadAccount,
	LampyPayloadAccountType,
	LampySchemeAccountBalancePayload,
	LampyAccountTransactionsPayload,
	LampyAccountsPayload,
	LampyGeneralLedgerPayload,
	SchemeBalancesData,
	TrialBalanceData,
	LampyFireflyStatementPayload,
	TransactionAccountPayload,
	GeneratedAgeAnalysis,
	NewAgeAnalysisRequirements,
	OldPastelTrialBalanceData,
};
