"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFireflyStatementInvoiceNumberAndDate = exports.getFireflyStatementDates = void 0;
var dayjs = require("dayjs");
function getFireflyStatementDates(type, params) {
    var startDate;
    var endDate;
    var beforeStartDate;
    switch (type) {
        case 'bulk':
            startDate = dayjs()
                .year(params.year)
                .month(params.month - 1)
                .date(22)
                .subtract(2, 'months');
            endDate = dayjs()
                .year(params.year)
                .month(params.month - 1)
                .date(1);
            break;
        case 'quick':
            startDate = dayjs().add(1, 'month').subtract(2, 'months').set('date', 1).format('YYYY-MM-DD');
            endDate = dayjs().add(1, 'month').set('date', 1).format('YYYY-MM-DD');
            break;
        case 'select':
            startDate = params.startDate;
            endDate = params.endDate;
            break;
        case 'history':
            startDate = '2000-05-01';
            endDate = dayjs().add(3, 'months').format('YYYY-MM-DD');
            break;
        case 'taxinvoice':
            var date = dayjs("".concat(params.year, "-").concat(dayjs().month(params.month - 1).format('MM'), "-22"));
            startDate = date.subtract(2, 'months').format('YYYY-MM-DD');
            endDate = date.set('date', 1).format('YYYY-MM-DD');
            break;
        case 'latest':
            var lastBilledStart = dayjs();
            var lastBilledEnd = dayjs();
            startDate = lastBilledStart.subtract(2, 'months').set('date', 1).format('YYYY-MM-DD');
            endDate = lastBilledEnd.set('date', 1).format('YYYY-MM-DD');
            break;
        default:
            throw new Error('Unable to calculate dates. (No type selected)');
    }
    if (startDate && endDate) {
        beforeStartDate = dayjs(startDate).subtract(1, 'days').format('YYYY-MM-DD');
        return { startDate: startDate, endDate: endDate, beforeStartDate: beforeStartDate };
    }
    else {
        throw new Error('Unable to calculate dates.');
    }
}
exports.getFireflyStatementDates = getFireflyStatementDates;
function getFireflyStatementInvoiceNumberAndDate(account, params) {
    var date;
    var taxInvoiceNumber;
    var dateArr = params.endDate.split('-');
    switch (params.statementType) {
        case 'quick':
            date = dayjs().format('YYYY-MM-DD');
            taxInvoiceNumber = "".concat(account).concat(params.endDate.replace(/-/g, ''));
            break;
        case 'taxinvoice':
            date = params.endDate;
            taxInvoiceNumber = "".concat(account).concat(params.endDate.replace(/-/g, ''));
            break;
        case 'history':
            date = dayjs().format('YYYY-MM-DD');
            taxInvoiceNumber = "".concat(account).concat(dayjs().format('YYYYMMDD'));
            break;
        case 'select':
            date = dayjs().format('YYYY-MM-DD');
            taxInvoiceNumber = "".concat(account).concat(dayjs().format('YYYYMMDD'));
            break;
        case 'latest':
            date = dayjs().format('YYYY-MM-DD');
            taxInvoiceNumber = "".concat(account).concat(dayjs().format('YYYYMMDD'));
            break;
        default:
            date = params.endDate;
            taxInvoiceNumber = "".concat(account).concat(dateArr[0]).concat(dateArr[1], "01");
            break;
    }
    if (date && taxInvoiceNumber) {
        return { date: date, taxInvoiceNumber: taxInvoiceNumber };
    }
    else {
        throw new Error('91 - Unable to calculate dates.');
    }
}
exports.getFireflyStatementInvoiceNumberAndDate = getFireflyStatementInvoiceNumberAndDate;
